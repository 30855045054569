import Image from "next/image";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import dayjs from "dayjs";
import FormConsultation from "../FormsThird/Consultation-Classic";
import { useModalContext } from "../../context/modalContext";

import Loading from "../../components/Loading";
import { CustomModal, Container, CloseBtn } from "./styled";
import { openSchedulePopupRequestAction } from "../../redux/actions/ui.actions";
import IconTimesClose from "../../public/icons/icon_times-close.svg";

const SchedulePopup = () => {
  const { openModal, closeModal, openModals } = useModalContext();
  const dispatch = useDispatch();
  const [cookie, setCookie] = useCookies(["popup"]);
  const { loading } = useSelector(
    ({ sentFranchiseData }) => sentFranchiseData || { loading: false }
  );
  const { openSchedulePopup } = useSelector(({ uiStateData }) => uiStateData);
  const [timeElapsed, setTimeElapsed] = useState(false);

  const showModal = () => {
    console.log(openModals, "before showing modal");
    if (!openModals.includes("schedule") && openModals.length < 2) {
      console.log(openModals, "showing modal");
      openModal("schedule");
    }
  };

  const isBeforeDay = (popupTime) =>
    dayjs(popupTime).isBefore(dayjs().format("YYYY-MM-DD", "day"));

  const persistPopupData = () => {
    setCookie("popup", dayjs().format("YYYY-MM-DD"), {
      path: "/",
      maxAge: 86400 * 3, // Expires after 3 days
      sameSite: true,
    });
  };

  const handleCancel = () => {
    persistPopupData();
    closeModal("schedule");
    setTimeElapsed(false);
  };

  useEffect(() => {
    const handleVisiblePopup = () => {
      setTimeout(() => {
        showModal();
      }, 120000);
    };
    if (!cookie.converted && (!cookie.popup || isBeforeDay(cookie.popup))) {
      handleVisiblePopup();
    }
  }, [cookie, openModals]);

  useEffect(() => {
    if (openSchedulePopup) {
      if (!cookie.popup || isBeforeDay(cookie.popup)) {
        showModal();
        dispatch(openSchedulePopupRequestAction(false));
      }
    }
  }, [cookie, dispatch, openModals, openSchedulePopup]);

  return (
    <CustomModal
      visible={timeElapsed && openModals.length <= 1}
      closable={false}
      centered
      footer={null}
      mask
    >
      <Container
        aria-label="schedule a consultation"
        aria-describedby="dialog-desc"
        role="alertdialog"
      >
        <CloseBtn aria-label="close" onClick={handleCancel} tabIndex={0}>
          <Image
            aria-hidden
            src={IconTimesClose}
            alt="close icon"
            layout="fill"
            loading="lazy"
            className="close-icon"
            quality={50}
          />
        </CloseBtn>
        <h2 id="dialog-desc">Let's Schedule a FREE Consultation</h2>
        <FormConsultation variant="small" />
        <Loading loading={loading} />
      </Container>
    </CustomModal>
  );
};

export default SchedulePopup;
